import React, { useRef, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../layout/Layout";
import { SearchContext } from "../context/SearchContext";
import Components from "../components/dynamic";
import Pagination from "../components/common/Pagination";
import ProductFilters from "../components/ProductFilters";
import SidebarList from "../components/category/SidebarList";
import useSearch from "../hooks/searchspring/useSearch";
import { isUserLoggedIn } from "../utils/auth";
import ProductSortFilter from "../components/searchSpring/ProductSortFilter";
import ProductListing from "../components/products/ProductListing";
import UnAuthorizedUser from "../sections/dynamic-page/UnAuthorizedUser";

function DynamicCategory({ data }) {
  const components = data.dynamicCategories.versions?.components || [];
  const type = data.dynamicCategories.versions?.type || "";
  const category_name = data.dynamicCategories.name;
  const is_customers_only = data?.dynamicCategories?.is_customers_only || false;
  const user = isUserLoggedIn();
  const [view, setProductView] = useState("grid");
  const pageWrapperRef = useRef(null);
  const categoryPageFilter = {
    [`bgfilter.categories_hierarchy`]: category_name,
  };
  const {
    filters,
    products,
    sortingOptions,
    isLoading,
    pagination,
    facets,
    filterSummary,
    handlePageChange,
    applyFilter,
    handlePriceChange,
    onPriceReset,
    onClearAllFilter,
    handleSortingOptionChange,
  } = useSearch(`categories_hierarchy`, categoryPageFilter, pageWrapperRef);
  const contextValues = {
    filters,
    isLoading,
    products,
    facets,
    view,
    filterSummary,
    sortingOptions,
    setProductView,
    applyFilter,
    handlePriceChange,
    onPriceReset,
    onClearAllFilter,
    handleSortingOptionChange,
  };
  const { currentPage, totalPages } = pagination;

  const CategoyToggle = () => {
    const bodyClass = document.body.classList;

    if (bodyClass.contains("category-toggle-active")) {
      bodyClass.remove("category-toggle-active");
    } else {
      document.body.classList.add("category-toggle-active");
    }
  };

  return (
    <>
      {!user && is_customers_only ? (
        <UnAuthorizedUser />
      ) : (
        <>
          <SearchContext.Provider value={contextValues}>
            <div className="page-wrapper" ref={pageWrapperRef}>
              <div className="container">
                <div className="row flex flex-wrap page-layout">
                  <div className="mobile-title-col show-mobile">
                    <h3 className="page-title page-title-mobile">
                      {category_name}
                    </h3>
                  </div>

                  <button
                    className="mobile-sidebar-toggle show-mobile"
                    onClick={() => CategoyToggle()}
                  >
                    <span className="show-filter">Show Filter</span>
                    <span className="hide-filter">Hide Filter</span>
                  </button>

                  <div className="col page-sidebar page-sidebar-toggle">
                    <div className="page-sidebar-inner-wrap">
                      {user ? <ProductFilters /> : null}
                      <SidebarList />
                    </div>
                  </div>

                  <div className="col page-content">
                    {components.length
                      ? components.map((item, index) => {
                        return (
                          <Components
                            data={item}
                            type={type}
                            key={`${item.code}-${index}`}
                          />
                        );
                      })
                      : null}

                    <div className="page-action-bar flex justify-space vertical-middle">
                      <h1 className="page-title hide-mobile">
                        {category_name}
                      </h1>

                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={totalPages}
                        onPageChange={(page) => handlePageChange(page)}
                      />

                      <div className="flex vertical-middle">
                        <ProductSortFilter />
                      </div>
                    </div>

                    <ProductListing />

                    <Pagination
                      className="pagination-bar"
                      currentPage={currentPage}
                      totalCount={totalPages}
                      onPageChange={(page) => handlePageChange(page)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </SearchContext.Provider>
        </>
      )}
    </>
  );
}

export const Head = ({ data }) => {
  const seoDetails = data.dynamicCategories.versions?.seo_details || {};

  return (
    <>
      <link
        rel="canonical"
        href={`${process.env.GATSBY_STOREFRONT_URL}${seoDetails?.page_url}`}
      />
      <title>{seoDetails?.meta_title}</title>
      <meta name="description" content={seoDetails?.meta_description || ""} />
    </>
  );
};

export const query = graphql`
  query GetDynamicCategoriesComponents($id: String!) {
    dynamicCategories(id: { eq: $id }) {
      id
      name
      is_visible
      url
      is_customers_only
      versions {
        type
        components {
          code
          variant {
            config {
              ...dynamicCategoriesVersionsComponentsVariantConfigFragment
            }
            admin_layout
          }
        }
        seo_details {
          page_name
          page_url
          meta_title
          meta_description
        }
      }
    }
  }

  fragment dynamicCategoriesVersionsComponentsVariantConfigFragment on dynamicCategoriesVersionsComponentsVariantConfig {
    side_images {
      image_url
      title
      visibility
      url
    }
    slider {
      image_url
      title
      visibility
      url
    }
    banners {
      image_url
      mobile_image_url
      title
      url
      visibility
    }
    logos {
      image_url
      id
      title
      url
      visibility
    }
    data
    image_url
    mobile_image_url
    title
    url
    visibility
  }
`;

DynamicCategory.Layout = Layout;
export default DynamicCategory;
